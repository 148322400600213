html {
  box-sizing: border-box;
}

*,
*:before,*:after {
  box-sizing: inherit;
}

.absolute--center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.opacity-transition-1 {
  transition: opacity 120ms ease-in;
}

.no-select {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.input-range-reset {
  -webkit-appearance: none;
  background: transparent;
}

.input-range-reset::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}
